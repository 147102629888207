import React from 'react';
import nuez from '../../assets/images/nuez.webp';
import b_flecha from '../../assets/images/b-flecha.webp';

const ProductTitle = ({}) => {
    return(<>
        <img className="nuez w-image" src={nuez} />
        <div className="container pt-5">
        <div className="row">
            <h4 className="text-center pt-4 pb-3">CONOCE NUESTROS PRODUCTOS</h4>
            <p className="text-center mb-5">Naturalmente ricos en fibra, vitaminas y minerales <br />que te nutren y ayudan a mejorar la digestión.</p>
        </div>
        </div>
        <img className="flecha w-100 mt-4" src={b_flecha} />
    </>)
}

export default ProductTitle;