import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';
import ReactPixel from 'react-facebook-pixel';


const ContactoLink = (props) => {
    const cel = "3172360243";
    const generateId = () => uuidv4();

    const redirectWhatsapp = (data) => {

        let current_timestamp = Math.floor(new Date() / 1000);
        const id_event = generateId();

        // Aqui va el codigo de PIXEL para cuando le da clic en Whatsapp
        ReactPixel.track('Contact', 
        {
            content_name: 'Contacto Brios',
            content_category: 'Contact',
            external_id: props.userId,
            custom_data: 
            { 
                nombre_minimarket: "Brios Whatsapp", 
                telefono: cel
            },
            user_data: {
                client_ip_address: props.ip,
                client_user_agent: window.navigator.userAgent,
                fbc: props.fbc,
                fbp: props.fbp
            }
        }, 
        { 
            eventID: id_event 
        });


        //Aqui va el codigo de SERVIDOR para Pixel cuando le da clic en whatsapp
        const datas = {
            "data": [
                {
                    "event_name": "Contact",
                    "event_time": current_timestamp,
                    "event_id": id_event,
                    "action_source": "website",
                    "user_data": {
                        "client_ip_address": props.ip,
                        "client_user_agent": window.navigator.userAgent,
                        "fbc": props.fbc,
                        "fbp": props.fbp,
                        "external_id": props.userId,
                    },
                    "custom_data": {
                        "content_name": 'Contacto Brios',
                        "content_category": 'Contact',
                        "nombre_minimarket": "Brios Whatsapp",
                        "telefono": cel
                    }
                }
            ]
        };

        fetch(
            `https://graph.facebook.com/v12.0/${props.pixel_id}/events?access_token=${props.access_token}`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(datas)
            }
        )
        .then(response => response.json())
        .then(response => {
            console.log('Evento enviado con éxito:', response);
            console.log(datas)
            window.open('https://wa.me/' + '+57' + cel)
        })
        .catch(error => {
            console.error('Error al enviar el evento:', error);
        });
    }

    return (<a className="btn btn-light appbtn2" onClick={(e) => redirectWhatsapp()} target='_blank' alt="Servicio al cliente WhatsApp Brios">
        <i className="fa-brands fa-whatsapp"></i> Servicio al cliente
      </a>)

}

export default ContactoLink