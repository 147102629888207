import React from 'react';
import frutosRojos from '../../assets/images/frutos-rojos.webp';

const AboutBrios = () => {
    return(
        <div className="row pt-5">
        <div className="col-sm-12 col-md-12 col-lg pt-5 pb-lg-5 d-flex align-items-center justify-content-center">
            <h2 className="text-center"><span>Línea</span> <br /> Brios </h2>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 d-flex align-items-center pt-lg-5 px-5">
            <div>
            <p className="text-justify pt-5">Sabemos que deseas alimentar a tu familia y a ti de forma saludable sin renunciar al placer de comer rico.
                <br />
                <br />
                La línea de pan tajado Brios te brinda el balance perfecto entre rico y saludable al combinar frutas, miel, nueces, cereales y semillas con nuestra masa madre 100% integral alta en fibra, todos sus ingredientes son de origen natural y de primera calidad.
                <br />
                <br />
                <strong>Somos tu alternativa rica y saludable</strong> en tu camino hacia un <strong>estilo de vida mejor.</strong>
            </p>
            </div>
        </div>
        <p className="text-end"><img src={frutosRojos} className="w-image" /></p>
        </div>
    )
}

export default AboutBrios;