import React, { useState, useEffect  } from "react";
import "./Carousel.css";

export const CarouselItem = ({ children, width}) => {

    return(children)
    /*return(
        <div className="carousel-item-circular" style={{ width: width}}>
            {children}
        </div>
    )*/
};

const Carousel = ({children}) => {
    const [activeIndex, setActiveIndex] = useState( window.innerWidth <= 800 ? 0 : 1);
    const [mobile, setMobile] = useState( window.innerWidth <= 800 );

    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth <= 800);
            setActiveIndex(window.innerWidth <= 800 ? 0 : 1);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const updateIndex = (newIndex) => {
        const count = React.Children.count(children);
        if(newIndex < 0) {
            newIndex = count - 1;
        } else if (newIndex >= count){
            newIndex = 0;
        }

        setActiveIndex(newIndex);
    }

    return (
        <div className="carousel">
            
            {!mobile && <button className="btn btn_prev" onClick={() => {
                updateIndex(activeIndex -1);
            }}>
                Prev
            </button>}

            <div className="inner" style={{ transform: `translateX(${mobile ? activeIndex * -100 : (activeIndex - 1) * -33.33}%)`}}>
                {React.Children.map(children, (child, index) => {
                    var cx = require('classnames');
                    return <div className={mobile ? "carousel-item-circular-movil active" : index === activeIndex ? "carousel-item-circular active" : "carousel-item-circular"} style={{width: `${mobile ? "100%": "33.33%"}`}} > 
                    {React.cloneElement(child, 
                        {
                            key: index, 
                            width: mobile ? "100%": "33%", 
                            className: cx(child.props.className, "active")
                        })
                    }
                    </div>;
                })}
            </div>
            
            {!mobile && <button className="btn btn_next" onClick={() => {
                updateIndex(activeIndex +1);
            }}>
                Next
            </button>}

            {!mobile && <div className="indicators">

                {React.Children.map(children, (child, index) => {
                    return(
                        <button
                        className={`${index === activeIndex ? "btn btn_circle active" : "btn btn_circle"}`}
                        onClick={() => {
                            updateIndex(index);
                        }}
                        >
                            {index + 1}
                        </button>
                    );
                })}
            </div>}
        </div>
    )
}

export default Carousel;
